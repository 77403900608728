import SkinDysfunctionsTemplate from '@/modules/questionnaire/components/steps/common/skin-dysfunctions/skin-dysfunctions/SkinDysfunctionsTemplate';

export default {
  title: 'Steps/SkinDysfunctions/SkinDysfunctionsTemplate',
  component: SkinDysfunctionsTemplate
};

const createStory = props => () => ({
  components: { SkinDysfunctionsTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  data() {
    return {
      currentValue: {
        drynessDiscomfort: 'low',
        greasySkinDiscomfort: 'very_high',
        textureDiscomfort: 'high'
      }
    };
  },
  methods: {
    onFieldChange({ fieldName, value }) {
      this.currentValue = {
        ...this.currentValue,
        [fieldName]: value
      };
    }
  },
  template:
    '<div :style="$options.wrapperStyles"><skin-dysfunctions-template :dysfunctions="currentValue" v-bind="$options.storyProps" @field-change="onFieldChange" /></div>'
});

export const Base = createStory({});
